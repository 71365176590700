import { graphql, useStaticQuery } from "gatsby";

const useProfilePic = () => {
	const data = useStaticQuery(
		graphql`
			query {
				angel: file(relativePath: { eq: "angel-li.jpg" }) {
					childImageSharp {
						fixed(height: 300) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				danielle: file(relativePath: { eq: "danielle-lam.jpg" }) {
					childImageSharp {
						fixed(height: 300) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				katrina: file(relativePath: { eq: "katrina-rogers.jpg" }) {
					childImageSharp {
						fixed(height: 300) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				spiro: file(relativePath: { eq: "spiro-raftopoulos.jpeg" }) {
					childImageSharp {
						fixed(height: 300) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				ian: file(relativePath: { eq: "ian-yusoff.jpg" }) {
					childImageSharp {
						fixed(height: 300) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				nabil: file(relativePath: { eq: "nabil-siddique.jpg" }) {
					childImageSharp {
						fixed(height: 300) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				marcelo: file(relativePath: { eq: "marcelo-leal.jpg" }) {
					childImageSharp {
						fixed(height: 300) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				sam: file(relativePath: { eq: "sam-rao.jpg" }) {
					childImageSharp {
						fixed(height: 300) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				duy: file(relativePath: { eq: "duy-pham.jpeg" }) {
					childImageSharp {
						fixed(height: 300) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				claire: file(relativePath: { eq: "claire-gordon.jpg" }) {
					childImageSharp {
						fixed(height: 300) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				muna: file(relativePath: { eq: "muna-salama.jpg" }) {
					childImageSharp {
						fixed(height: 300) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				daniel: file(relativePath: { eq: "daniel-cheng.jpg" }) {
					childImageSharp {
						fixed(height: 300) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	);
	return data;
};

export default useProfilePic;
