import { Link } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import useProfilePic from "../../hooks/useProfilePic";

const ProfileLink = ({ name, qualifications, pic, link, snippet }) => (
	<div className="rounded bg-grey-lighter p-4 shadow-md flex flex-col justify-start">
		<Img alt="A/Prof Spiro Raftopoulos, Gastroenterologist" fixed={pic.childImageSharp.fixed} />
		<h2 className="text-primary font-medium text-lg mt-3">{name}</h2>
		<p className="text-black font-medium">{qualifications}</p>
		<p className="my-5 text-black flex-grow">{snippet}</p>
		<div className="text-center mt-5">
			<Link to={`/our-team/${link}`}>
				<button className="hover:bg-primary-dark bg-primary text-white py-1 px-4 rounded mx-auto" type="button">
					Read more
				</button>
			</Link>
		</div>
	</div>
);

ProfileLink.propTypes = {
	name: PropTypes.string.isRequired,
	qualifications: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	snippet: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	pic: PropTypes.object.isRequired,
};

const OurTeam = () => {
	const { spiro, ian, marcelo, sam, duy, claire, daniel, angel, nabil, danielle, katrina, muna } = useProfilePic();
	return (
		<>
			<h1 className="h1">Specialists working at Advanced GI</h1>
			<div
				style={{ gridTemplateColumns: `repeat(auto-fill, calc(2rem + 200px))` }}
				className="grid gap-12 leading-snug justify-center"
			>
				<ProfileLink
					name="Spiro Raftopoulos"
					qualifications="MBBS, FRACP"
					snippet="A/Prof Spiro Raftopoulos prides himself in the delivery of expert management in all aspects of general gastroenterology, therapeutic endoscopy and high quality colonoscopic services."
					link="spiro-raftopoulos"
					pic={spiro}
				/>
				<ProfileLink
					name="Ian Yusoff"
					qualifications="MBBS, FRACP"
					snippet="Clinical Prof. Ian Yusoff is a gastroenterologist with subspecialty interests in pancreaticobiliary diseases. Procedurally, his main interests are in endoscopic ultrasound and pancreaticobiliary endoscopy."
					link="ian-yusoff"
					pic={ian}
				/>
				<ProfileLink
					name="Marcelo Leal"
					qualifications="MBBS, FRACP"
					snippet="Dr Marcelo Leal is a Consultant Paediatric Gastroenterologist and Hepatologist who holds a substantial public appointment at Perth Children’s Hospital."
					link="marcelo-leal"
					pic={marcelo}
				/>
				<ProfileLink
					name="Claire Gordon"
					qualifications="MBBS(Hons), FRACP"
					snippet="Dr Claire Gordon is a gastroenterologist with a subspecialty interest in luminal gastroenterology, particularly inflammatory bowel disease."
					link="claire-gordon"
					pic={claire}
				/>
				<ProfileLink
					name="Sam Rao"
					qualifications="MBBS, FRACP"
					snippet="Dr Sam Rao is a gastroenterologist with special interest in therapeutic endoscopy and pancreaticobiliary disease."
					link="sam-rao"
					pic={sam}
				/>
				<ProfileLink
					name="Duy Pham"
					qualifications="MBBS, FRACP"
					snippet="Dr Duy Pham is a consultant Gastroenterologist and Hepatologist with special interest in all aspects of liver disease."
					link="duy-pham"
					pic={duy}
				/>
				<ProfileLink
					name="Daniel Cheng"
					qualifications="BMed Sci (Hons), MBBS, FRACP"
					snippet="Dr Daniel Cheng is a gastroenterologist who is dedicated to delivering holistic and patient focused care in general gastroenterology, therapeutic endoscopy and Inflammatory Bowel Disease."
					link="daniel-cheng"
					pic={daniel}
				/>
				<ProfileLink
					name="Angel Li"
					qualifications="MBBS (Hons), FRACP"
					snippet="Dr Angel Li is a Gastroenterologist who is passionate about gut health and providing holistic care to her patients. She has subspecialty interests in general and luminal gastroenterology, including inflammatory bowel disease."
					link="angel-li"
					pic={angel}
				/>
				<ProfileLink
					name="Nabil Siddique"
					qualifications="MBBS, MRCP(UK), FRCP, FRACP"
					snippet="Dr Siddique is a UK trained gastroenterologist who has extensive expertise in all aspects of luminal gastroenterology including inflammatory bowel diseases, general gastroenterology and nutrition."
					link="nabil-siddique"
					pic={nabil}
				/>
				<ProfileLink
					name="Danielle Lam"
					qualifications="MBBS, FRACP"
					snippet="Dr Danielle Lam has extensive expertise in all aspects of luminal gastroenterology including inflammatory bowel diseases. She is committed to providing a high standard of holistic care in an empathetic, effective and professional manner."
					link="danielle-lam"
					pic={danielle}
				/>
				<ProfileLink
					name="Katrina Rogers"
					qualifications="MBBS, FRACP"
					snippet="Dr Katrina Rogers is a gastroenterologist who offers comprehensive and holistic care for her patients for a broad range of luminal disorders including inflammatory bowel disease."
					link="katrina-rogers"
					pic={katrina}
				/>
				<ProfileLink
					name="Muna Salama"
					qualifications="MBBS, FRACP"
					snippet="Dr Muna Salama is an experienced, compassionate and well-rounded gastroenterologist. She has a specific interest in the management of inflammatory bowel disease (IBD)."
					link="muna-salama"
					pic={muna}
				/>
			</div>
		</>
	);
};

export default OurTeam;
